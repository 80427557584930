import React from "react";
import styled from "styled-components";
import Button from "../button";
interface DialogBoxProps {
  headline: string;
  stacked?: boolean;
  closeAction?: (arg0: boolean) => void;
  children: React.ReactChild | React.ReactChild[];
}

const Wrapper = styled.div<{ $stacked: boolean }>`
  background-color: #e4e4e4;
  position: relative;
  grid-row: 1;
  display: inline-block;
  grid-column: 1 / -1;
  ${({theme}) => theme.mediaQuery.s500} {
    grid-column: 3 / 7;
  }
  border: 2px #e5e5e5 inset;
  padding: 10px;
  color: #000;
  box-shadow: 0px 0px 50px rgb(255 255 255 / 41%);
  ${({$stacked}) => $stacked && `
    transform: translate(8px, -8px); 
    z-index:10;
    position:absolute;
    bottom:0;
  `}
`;
const HeadlineBar = styled.div`
  background: #1619c7;
  color: white;
  padding: 7px 10px;
  text-align: left;
  margin:-10px;
`;
const CloseButton = styled(Button)`
  position: absolute;
  right: 6px;
  cursor: pointer;
  background: #cccccc;
  width: 20px;
  height: 20px;
  color: black;
  border: 1px outset;
  top: 6px;
  text-align: center;
  padding: 0;
  line-height: 10px;
  font-size: 1rem;
`;
const DialogBox = ({stacked, headline, closeAction, children}: DialogBoxProps) => {
  return (  
    <Wrapper $stacked={!!stacked}>
      <HeadlineBar>{headline} {closeAction && <CloseButton title="Close Dialog" onClick={() => closeAction(false)}>X</CloseButton>}</HeadlineBar>
      {children}
    </Wrapper>
  );
}
export default DialogBox;