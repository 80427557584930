import React from "react"
import styled from "styled-components"

interface TextBoxProps {
  children: string
}

const StyledTextBox = styled.p`
  font-size: 1.3rem;
  padding: 40px 0 30px;
  z-index: 1;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
`
const TextBox = ({children}: TextBoxProps) => {
  return <StyledTextBox>{children}</StyledTextBox>
}
export default TextBox;