import React from "react";
import styled, {keyframes} from 'styled-components';
const sunWiggle = keyframes`
  0%, 50%{
    background-position: center 0px;
    filter:blur(0px);
  }
  25% {
    background-position: center -10px;
    filter:blur(2px);
  }
`;
const StyledSun = styled.div`
  width:80vw;
  ${({theme}) => theme.mediaQuery.s500} {
    width: 50vw;
  }
  overflow: hidden;
  aspect-ratio: 2 / 1;
  filter: drop-shadow(0px 0px 5px rgba(255,255,255,.5));
  margin: 0 auto;
    > div {
      width:80vw;
      ${({theme}) => theme.mediaQuery.s500} {
        width: 50vw;
      }
      aspect-ratio: 1 / 1;
      max-width: 100%;
      background: linear-gradient(#ffecb8 10%,transparent 10%,transparent 15%,#ffecb8 15%,#ff78e7 20%,transparent 20%,transparent 25%,#ff78e7 25%,var(--color-sun-line) 30%,transparent 30%,transparent 33%,var(--color-sun-line) 33%,#ff00d9 37%,transparent 37%,transparent 38%,#ff00d9 38%,#ff00d9 39%,transparent 39%,transparent 40%,#ff00d9 40%,#ff00d9 41%,transparent 41%,transparent 42%,#ff00da 42%,#eb82ff 43%,transparent 43%,transparent 44%,#fc77ff 44%,#9197f8 45%,transparent 45%,transparent 46%,#9a8ef9 46%,#00e9ff 47%,transparent 47%,transparent 48%,#00e9ff 48%,#00e9ff 49%,transparent 49%,transparent 50%);
      border-radius: 100%;
      animation-name: ${sunWiggle};
      animation-duration: 10s;
      animation-iteration-count: infinite;
    }
`;
const Sun = ({...props}) => {
  return <StyledSun {...props}><div></div></StyledSun>;
}
export default Sun;