import React from "react";
import styled, {keyframes} from 'styled-components';

const flicker = keyframes`
	0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
    opacity: .99;
	}
	20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
		opacity: 0.4;
	}
`;
const HeaderWrap = styled.header`
  text-align: center;
  display: inline-block;
  padding: 20px;
  ${({theme}) => theme.mediaQuery.s500} {
    padding: 20px 40px;
  }
  background: rgb(0 0 0 / 40%);
  box-shadow: 0px 0px 25px #666666;
  border: 4px solid #727bf0;
  margin: 20px auto;
`
const Headline = styled.h1`
    color: #00f1ff;
    margin:0;
    font-family: 'Bungee Hairline';
    text-shadow: #00f1ff 0px 0px 20px, #00f1ff 0px 0px 7px;
    font-size: clamp(2rem,12vw,10rem);
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    &:before{

      font-size: 1.8rem;
      left: -20px;
      top: -31px;
      ${({theme}) => theme.mediaQuery.s500} {
        font-size: 2.8rem;
        left: -50px;
        top: -25px;
      }
      content: "Welcome to";
      font-family: 'Mr Dafoe',cursive;
      position: absolute;
      color: #ff00da;
      text-shadow: #ff00dc6e 0 0 5px;
      text-transform: none;
      transform: rotate(-10deg);
      animation-name: ${flicker};
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }
    &:after{
      content: ".com";
      font-size: 1.1rem;
      position: absolute;
      bottom: 0px;
      right: 15px;
    }
`;
const Header = () => (
  
<HeaderWrap>
  <Headline>Bjørn Mann</Headline>
</HeaderWrap>
);
export default Header