import React from "react";
import styled from "styled-components";
export interface ButtonProps {
  children?: React.ReactElement|string;
  isLink?: boolean;
  href?: string;
  target?: string;
  title?: string,
  onClick?: React.MouseEventHandler<HTMLAnchorElement> ;
  as?: string;
}
const StyledButton = styled.button`
    position: relative;
    z-index: 10;
    background: #cccccc;
    color: #010101;
    border: 2px outset rgb(181 181 181);
    box-shadow: 2px 2px, 2px -2px, -2px -2px, -2px 2px;
    padding: 5px 30px;
    text-decoration: none;
    font-size: 1.5rem;
    &:hover,
    &:focus{
      cursor: pointer;
      outline-offset: -9px;
      outline-color: #656565;
      outline-style: dotted;
    }
    &:active{
      outline-style: none;
      border: 2px inset rgb(181 181 181);
    }
`;
const Button = ({children, isLink, onClick, as, ...props}: ButtonProps) => <StyledButton onClick={onClick ? onClick : ()=>(null) } as={isLink ? "a" : "button"} {...props}>{children}</StyledButton>;
export default Button;
