import React, {useEffect, useState} from "react"
import styled from "styled-components";
import Layout from "../layout";
import Button from "../components/button";
import DialogBox from "../components/dialog-box";
import Sun from '../components/sun';
import Headline from '../components/header';
import TextBox from '../components/text-box';
const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
`;
const Dialogs = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  ${({theme}) => theme.mediaQuery.s500} {
    grid-template-columns: repeat(8,1fr);
  }
  max-width: 900px;
  margin: auto;
  padding: 50px 0;
  z-index: 1;
`;

const IndexPage = () => {
  const [selectedDialog, setSelectedDialog] = useState<string>('');
  useEffect(()=>{
    console.log('%c If you\'re looking in here, why not just say hello? %ctalk@bjornmann.com', 'color: rgb(0, 241, 255);margin: 0px;font-family: sans-serif;font-weight:100;text-shadow: rgb(0 241 255) 0px 0px 20px, rgb(0 241 255) 0px 0px 7px;font-size: 20px;text-transform: uppercase;', 'font-size: 20px;font-family: sans-serif;color: rgb(255, 0, 218);text-shadow: rgb(255 0 220 / 43%) 0px 0px 5px;text-transform: none;');
  }, [])
  return (
    <Layout>
      <Sun />
      <Headline />
      <Dialogs>
        <DialogBox headline='What brings you in today?'>
          <TextBox>
            There's only one right choice, it's both. Choose one.
          </TextBox>
          <ButtonContainer>
            <Button title="Business" onClick={() => setSelectedDialog('business')}>Business</Button>
            <Button title="Pleasure" onClick={() => setSelectedDialog('pleasure')}>Pleasure</Button>
          </ButtonContainer>
        </DialogBox>
        {selectedDialog === 'business' &&
          <DialogBox stacked headline='Very serious' closeAction={()=> setSelectedDialog('')}>
            <TextBox>
              I can do what you need done.
            </TextBox>
            <ButtonContainer>
              <Button isLink href="https://www.linkedin.com/in/bjornmann">LINKEDIN&trade;</Button>
              <Button title="Cancel" onClick={() => setSelectedDialog('')}>Forget it</Button>
            </ButtonContainer>
          </DialogBox>
        }
        {selectedDialog === 'pleasure' &&
          <DialogBox stacked headline='My Pleasure' closeAction={()=> setSelectedDialog('')}>
            <TextBox>
              I like to have fun. It's what makes me unique.
            </TextBox>
            <ButtonContainer>
              <Button target="_blank" isLink href="https://www.yougonext.com/?people=Yes,No,Maybe&message=Should%20you%20go%20back%20to%20Bjorn%27s%20website?">YouGoNext.com</Button>
              <Button target="_blank" isLink href="https://beachicken.com">BeAChicken.com</Button>
              <Button target="_blank" isLink href="https://overallbetter.com">OverallBetter.com</Button>
              <Button target="_blank" isLink href="https://clusterfuckkitchen.com">ClusterFuckKitchen.com</Button>
              <Button target="_blank" isLink href="https://dogthumbs.com">DogThumbs.com</Button>
              <Button target="_blank" isLink href="https://formalfetus.com">FormalFetus.com</Button>
              <Button target="_blank" isLink href="https://budlightemerald.com">BudLightEmerald.com</Button>
            </ButtonContainer>
          </DialogBox>
        }
      </Dialogs>
    </Layout>
  )
}

export default IndexPage
